<script setup lang="ts">
import CiviqaImg from './CiviqaImg.vue';

type colorOption = 'red' | 'green' | 'blue'

const props = defineProps({
    photoUrl: {
        type: String as PropType<string | null | undefined>,
        required: false,
    },
    name: {
        type: String as PropType<string | null | undefined>,
        required: false,
    },
    color: {
        type: String as PropType<colorOption>,
        required: false,
    },
    ringSize: {
        type: Number,
        required: false,
        default: 2,
    },
    noTooltip: {
        type: Boolean,
        required: false,
        default: false,
    },
    hoverMaskCallback: {
        type: Function as PropType<() => void>,
        required: false,
    },
    square: {
        type: Boolean,
        required: false,
        default: false,
    },
})

const placeholderImage = computed(() => {
    const background = props.color === 'red' ? 'FEF2F2' : props.color === 'blue' ? 'EFF6FF' : 'F0FDF4'
    const color = props.color === 'red' ? 'B91C1C' : props.color === 'blue' ? '1D4ED8' : '15803D'
    const params = {
        name: encodeURIComponent(props.name ?? "unknown"),
        size: 128,
        background,
        color,
    }
    const paramString = Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&')
    return `https://ui-avatars.com/api/?${paramString}`
})

const src = ref(props.photoUrl && props.photoUrl.length > 0 ? props.photoUrl : placeholderImage)

const ringColor = computed(() => {
    switch (props.color) {
        case 'red':
            return 'ring-red-700'
        case 'blue':
            return 'ring-blue-700'
        default:
            return 'ring-green-700'
    }
})
</script>

<template>
    <UTooltip :prevent="noTooltip || !name" :text="name" class="h-full w-full">
        <div class="h-full w-full relative flex-none overflow-clip"
        :class="square ? 'aspect-w-1 aspect-h-1' : '!rounded-full'"
        >
            <div v-if="hoverMaskCallback" @click="hoverMaskCallback()"
                class="absolute h-full w-full z-10 cursor-pointer bg-gray-600 opacity-0 hover:opacity-30">
                <div class="absolute inset-0 flex items-center justify-center ">
                    <slot />
                </div>
            </div>
            <CiviqaImg class="h-full w-full object-cover object-center" :class="[
        ringSize && ringColor ? `ring-${ringSize} ${ringColor} ring-offset-1` : '',
    ]" :src="src" :placeholder="placeholderImage"
                :background="color === 'red' ? 'bg-red-100' : color === 'blue' ? 'bg-blue-100' : 'bg-green-100'"
                @error="src = placeholderImage" />
        </div>
    </UTooltip>
</template>
